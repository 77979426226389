<template>

  <div class="pb"> 

      <h1>{{ $t('main_menu_contact_title') }}</h1>

      <p>If you have any questions regarding our products or want to address an issue, please don’t hesitate to contact us – no problem will be left unsolved!</p>
      <p>You have lots of opportunities to reach out to us: via our online chat, social media, our Contact Form, or email. Support Team is there to talk 24/7.</p>

      <br>

      <div class="contact_forms">

        <div class="contact_col"> 

            <!-- FORM -->

            <h2>Feedback</h2>
  

            <form id="myForm" onsubmit="ContactManager.go();return false;">



              <div class="ui form">

                <br>

                <UiInput
                  label="Your name"
                  :text="form_name"
                  type="text"
                  @change:text="form_name = $event"
                  />
                   

                <UiInput
                  label="E-mail"
                  :text="form_email"
                  type="text"
                  @change:text="form_email = $event"
                  />
                    


                <div class="field"> 
                  <label>{{ $t('main_menu_contact_topic') }}</label>
                  <div>
                    <select class="select_topic" v-model="form_topic">
                      <option value="1">{{ $t('main_menu_contact_topic1') }}</option>
                      <option value="2">{{ $t('main_menu_contact_topic2') }}</option>
                      <option value="3">{{ $t('main_menu_contact_topic3') }}</option>
                      <option value="4">{{ $t('main_menu_contact_topic4') }}</option>
                      <option value="5">{{ $t('main_menu_contact_topic5') }}</option>
                    </select> 
                  </div>
                </div>
                <div class="field"> 
                  <br>
                  <textarea v-model="form_message" rows="6" cols="" :placeholder="$t('main_menu_contact_placeholder')"></textarea>
                </div>
 

              </div>


              <UiUploader
                :url="'/uploads/contact/media'"
                :btn-name="'Photos or Screenshots'"
                :btn-wide="true"
                :enable-drag-drop="!photos.length"
                @changed="changePhotos" 
                @uploaded="uploadedPhotos" 
                @progress="uploadingPhotos" 
                />


              <div class="photos">

                <UiUploadPhoto
                  v-for="(opt,opt_key) in photos"
                  :key="opt_key"
                  :item="opt"
                  :url="opt.url"
                  v-show="opt?.is_remove != 1"
                  @remove="removePhoto(opt_key)"
                  @update:item="opt = $event"
                  />
                    
              </div>

              <NuxtTurnstile  ref="turnstile" v-model="token" />

              <div class="btns_set">

                <UiButton
                  :type="'primary'"
                  @click="sendMessage"
                  :is-loading="is_sending"
                  :name="$t('universal_button_send')">
                </UiButton>
                <UiButton
                  :tag="'NuxtLink'"
                  :to="'/'"
                  :name="$t('universal_button_cancel')">
                </UiButton>
                 

              </div>
                      
            </form>

            <!--  -->


        </div> 
        <div class="em_col">

          <h2>E-mails</h2>

          <br>

          <div class="emails">
            <div class="eml">
              <div class="name">
                Support
              </div>
              <div class="hint">
                Stay with you when needed
              </div>
              <div class="addr">
                <a href="mailto:info@growdiaries.com">info@growdiaries.com</a>
              </div>
            </div>
            <div class="eml">
              <div class="name">
                Sales
              </div>
              <div class="hint">
                Advertising opportunities
              </div>
              <div class="addr">
              <a href="mailto:nadya@growdiaries.com">nadya@growdiaries.com</a>
              </div>
            </div>
            <div class="eml hidden">
              <div class="name">
                Project manager
              </div>
              <div class="hint">
                Ask something important
              </div>
              <div class="addr">
              <a href="mailto:content@askgrowers.com">manager@growdiaries.com</a>
              </div>
            </div>
          </div>



          <br>

          <h2>Contacts</h2>

          <br>

          <div class="emails">
            <div class="eml">
              <div class="name">
                Address
              </div>
              <div class="hint">
                <!--googleoff: all-->LιndΙeyοva 2822/10, Dejvιce, 160 00 Praha 6, Czech RepυbΙιc<!--googleon: all-->
              </div>
            </div>
            <div class="eml">
              <div class="name">
                Phone
              </div>
              <div class="hint">
                <!--googleoff: all-->+420 773 602 983<!--googleon: all-->
              </div> 
            </div>
            <div class="eml hidden">
              <div class="name">
                Project manager
              </div>
              <div class="hint">
                Ask something important
              </div>
              <div class="addr">
              <a href="mailto:content@askgrowers.com">manager@growdiaries.com</a>
              </div>
            </div>
          </div>

          <br>

          <h2>Socials</h2>

          <br>

          <a class="soc" href="https://www.facebook.com/growdiaries/?fref=ts">
            <i class="icon-facebook"></i> Facebook
          </a>

          <a class="soc" href="https://www.instagram.com/grow.diaries/">
          <i class="icon-instagram"></i> Instagram
          </a>

          <a class="soc" href="https://www.pinterest.com/growdiaries/growdiariescom-daily-marijuana-pics/">
            <i class="icon-pinterest"></i> Pinterest
          </a>



        </div>
      </div>





  </div>

</template>

<script setup lang="ts">

const { $head, $api, $ga, $err, $popup } = useNuxtApp();

const form_topic = ref(5);
const form_message = ref(null);
const form_name = ref(useAuth().isAuth().user ? useAuth().user.value.name : null);
const form_email = ref(null);
const photos = ref([]);
const is_sending = ref(false);
const token = ref(null)
const turnstile = ref()


const getDetails = () => {
  return [
    'getPrevUrl: ' + $err.getPrevUrl(),
    'userAgent: ' + navigator?.userAgent,
    'width: ' + window?.innerWidth,
    'height: ' + window?.innerHeight,
    'authGuest: ' + useAuth().getGuest(),
    'storeKeys: ' + $err.getStoreKeys(),
    'cookieKeys: ' + $err.getCookieKeys(),
  ].join("\n \n ");
}

const sendMessage = async () => {

  if(!form_topic.value){
    $popup.error('Please choose topic');
    return;
  }
  if(!form_email.value){
    $popup.error('Please choose email');
    return;
  }
  if(!form_message.value){
    $popup.error('Empty message');
    return;
  }
  if(!useAuth().isAuth() && (!form_name.value || !form_email.value)){
    $popup.error('Empty name or email');
    return;
  }

  is_sending.value = true;


  // var recaptcha = '';
  // try {
  //   var recaptcha = await execute()
  // } catch (error) {
  //   is_sending.value = false;
  //   $popup.error('Google captcha error. Try again');
  //   return;
  // }

  // var recaptcha = await execute()
  
  $api.postContact(
    {
      topic: form_topic.value,
      message: form_message.value,
      name: form_name.value,
      email: form_email.value,
      photos: photos.value.map((opt) => {
        return opt.url
      }),
      details: getDetails(),
      recaptcha: token.value
    }
  )
  .then((res) => {
    is_sending.value = false;
     $popup.success('Sending completed');
     form_message.value = '';
     photos.value = [];
  })
  .catch(function (error) {
    turnstile.value?.reset();
    is_sending.value = false;
     $popup.error('Sending fail');
  });
}


// photos

const changePhotos = function(uploading_id, thumbnail){
  photos.value.push({is_loading: true, uploading_id: uploading_id, thumbnail: thumbnail, progress: 0});
}

const findReplaceUploadingPhoto = function(uploading_id, item){
  photos.value.forEach((opt, opt_key) => {
    if(opt?.uploading_id == uploading_id){
      photos.value[opt_key] = item; 
    }
  });
}

const findReplaceUploadingPhotoProgress = function(uploading_id, progress){
  photos.value.forEach((opt, opt_key) => {
    if(opt?.uploading_id == uploading_id){
      photos.value[opt_key]['progress'] = progress; 
    }
  });
}

const uploadingPhotos = function(progress, uploading_id){      
  findReplaceUploadingPhotoProgress(uploading_id, progress);
}

const uploadedPhotos = function(item, uploading_id){      
  findReplaceUploadingPhoto(uploading_id, item);
}

const removePhoto = function(key){            
  photos.value.splice(key,1);      
  // photos[key].is_remove = 1;  
}

// 

useHead(useNuxtApp().$head.getStaticPage());
 

</script>

<style scoped>


.contact_forms{
  display: flex;
  flex-wrap: wrap;

}
.contact_forms > *{
  width: 50%;
}



.contact_col{
    background-color: var(--un-background-color-gray);
    border-radius: 1rem;
    padding: 2rem!important;
}
.em_col{ 
    padding: 2rem!important;
}

.photos{
  margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
@container pb (max-width: 700px) {

  .contact_forms > *{
    width: 100%;
  }
}


/* form */
.btns_set{
    text-align: left;
    margin-top: 20px;
}
.form_login{
    text-align: center!important;
}

.btns_set img{
    width: 40%;
}
.capt{

}
.capt input{
    display: inline-block;
    width: 130px!important;
    margin-right: 1rem;
}
.capt img{
    height: 36px;
}
.select_topic{
  margin: 0em;
    max-width: 100%;
    width: 100%;
    flex: 1 0 auto;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: 1.2142em;
    padding: 0.6rem 1rem;
    background: var(--gd-input-back-color);
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: var(--gd-text-color);
    border-radius: 0.28571429rem;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
}


/*  */


.emails{}
.emails .eml{
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    /* grid-template-rows: 1fr 1fr; */
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "c1 fst"
        "c2 c2";
    margin-bottom: 1rem;
}
.emails .eml .name{
    grid-area: c1;
    font-weight: bold;
}
.emails .eml .hint{
    grid-area: c2;
    color: gray;
}
.emails .eml .addr{
    grid-area: fst;
    text-align: right;
    text-decoration: underline;
}

.soc{
    font-size: 1rem;
    margin-right: 1rem;
}
.soc i{
    font-size: 1.3rem!important;
    vertical-align: sub;
    margin-right: 0px!important;
}
.soc .icon-facebook{
    color: #39519a;
}
.soc .icon-instagram{
    color:#dd0067;
}
.soc .icon-pinterest{
    color:red;
}
 
</style>